import React, { Component } from "react";
import { Card } from "antd";
import { CheckOutlined  } from "@ant-design/icons";


class Checkout extends Component {

    render() {
        let checkoutRight = (
            <React.Fragment>
                <span>Down Payment Completed <CheckOutlined style={{ background: 'green'}} /> </span>
            </React.Fragment>
        );
        return (
            <React.Fragment>
            <div style={{ background: "#f1f1f1"}}>
                <div style={{ width: "500px", marginTop: "-100px"}}>
                    <Card style={{ marginTop: "-20px", width: "200" }} size="default" title={checkoutRight}>
                        <span>We are excited to have you as a customer! Your payment was successful. Please reach out to <b>support@testemail.com</b> with your invoice id for further questions.</span>
                        <hr></hr>
                    </Card>
                </div>
            </div>
            
        </React.Fragment>
        )
        

        
    }
};

export default Checkout;

