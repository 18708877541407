import React, { Component } from "react";
import { Input, Button, Tooltip, message } from "antd";
import { ArrowRightOutlined  } from "@ant-design/icons";

import { sendEmail } from "../../service";


class Checkout extends Component {

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            invoice_id: ''
        }
    }

    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value});
    }

    handleClick = async (e) => {
        if(!this.state.email || !this.state.invoice_id) {
            message.error('Please enter both fields to proceed')
            return;
        } else {
            const response = await sendEmail({ email: this.state.email, invoice_id: this.state.invoice_id});
            if(response.data.status === 202 || response.data.status === 200) {
                message.success("Email successfully sent");
            } else {
                message.error("Unable to send email");
            }
        }
    }


    render() {
        return (
            <React.Fragment>
            <div style={{ background: "#f1f1f1"}}>
                <div style={{ width: "500px", marginTop: "-100px"}}>
                <Tooltip placement="topLeft" title="Email as mentioned in pricing sheet">
                    <Input id="email" placeholder="Email" onChange={this.handleChange} />
                </Tooltip>
                </div>
                <div style={{ width: "500px", marginTop: "20px", paddingTop: "15px"}}>
                    <Tooltip placement="topLeft" title="Invoice id as mentioned in the pricing sheet">
                        <Input id="invoice_id" placeholder="Invoice id" onChange={this.handleChange} />
                    </Tooltip>
                </div>
                <div style={{ marginTop: "20px", paddingTop: "20px" }}>
                    <Button onClick={this.handleClick} disabled={false} type="primary" style={{ width: "500px", background: "#3c4257", borderColor: "#3c4257" }}>
                        <span style={{ color: "#ffffff"}}>Send Email <ArrowRightOutlined /></span>
                    </Button>
                </div>
            </div>
            
        </React.Fragment>
        )
    }
};

export default Checkout;

