import React from "react";
import { Switch, Route } from "react-router-dom";

import Checkout from "./Components/Checkout";
import Invoice from "./Components/Invoice";
import Thankyou from "./Components/Thankyou";
import Onboard from "./Components/Onboard";

const routes = props => (
    <Switch>
        <Route 
            exact
            render={routeProps => <Checkout {...props} {...routeProps}/>}
            path="/"
        />
        <Route
            exact
            render={routeProps => <Checkout {...props} {...routeProps} />}
            path="/checkout"
        />
        <Route
            exact
            render={routeProps => <Invoice {...props} {...routeProps} />}
            path="/invoice"
        />
        <Route
            exact
            render={routeProps => <Thankyou {...props} {...routeProps} />}
            path="/thankyou"
        />
        <Route 
            exact
            render={routeProps => <Onboard {...props} {...routeProps} />}
            path="/onboard"
        />
    </Switch>
);

export default routes;
