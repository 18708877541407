import React, { Component } from "react";
import { message, Button, Spin } from "antd";
import { CopyOutlined } from "@ant-design/icons";

import { uploadInvoice } from "../../service";

import "./style.css";
import "./style.scss";

class Invoice extends Component {

    constructor(...props) {
        super(...props);
        this.state = {
            fileList: '',
            file: {},
            isLoading: false
        }
        this.inputOpenFileRef = React.createRef();
        this.handleClick = this.handleClick.bind(this);
    }
    onFileChange = (e) => {
    }

    handleSubmit = (e) => {
        e.preventDefault();
    };

    handleClick = (e) => {
        e.preventDefault();
        this.inputOpenFileRef.current.click();
    };

    handleDrag = (e) => {
        e.preventDefault();
    };

    validateFiles = (files) => {
        const file = files[0]
        if (!file) {
            return {
                "message": "Please upload a valid file",
                "error": true
            }
        }

        const mimetype = file.type
        const validTypes = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"]
        if(!validTypes.includes(mimetype)) {
            return {
                "message": "Please upload the invoice in spreadsheet format",
                "error": true
            }
        }

        if (!file.name || file.name === "") {
            return {
                "message": "Please give the file a valid name",
                "error": true
            }
        }

        const file_split_contents = file.name.split(".")
        const extension = file_split_contents[file_split_contents.length - 1];
        const validExtensions = ["xlsx", "xls"]
        if (!validExtensions.includes(extension)) {
            return {
                "message": "Invalid file uploaded, expected a spreadsheet",
                "error": true
            }
        }

        const filesize = ((file.size/1024)/1024).toFixed(4);
        if (filesize > 10) {
            return {
                "message": "File Size too big",
                "error": true
            }
        }

        return {
            "message": "No Errors",
            "error": false
        }

    };

    handleChange = async (e) => {
        e.preventDefault();
        const files = e.target.files;
        const response = this.validateFiles(files);
        if(!response.error) {
            await this.fileUploadComponent(files);
        } else {
            message.error(response.message)
        }
    }

    handleDrop = async (e) => {
        e.preventDefault();
        const response = this.validateFiles(e.dataTransfer.files);
        if(!response.error) {
            await this.fileUploadComponent(e.dataTransfer.files);
        } else {
            message.error(response.message)
        }
    }

    handleSubmit = async (e) => {
        try {
            e.preventDefault();
            this.setState({ isLoading: true });
            let formData = new FormData();
            formData.append('file', this.state.file);
            const response = await uploadInvoice(formData);
            if (response.data.status === "error") {
                message.error(response.data.message);
            } else {
                message.success("Invoice Uploaded Successfully");
                this.setState({ isLoading: false });
            }
        } catch(e) {
            console.error(e);
        }
    };


    fileUploadComponent = async (files) => {

        let template = Object.keys(files).map((file, index) => {
            const filenameClass = `file file--${file}`;
            return (
                <React.Fragment key={index}>
                    <div className={filenameClass} id={filenameClass}>
                        <div className="name"><span>{files[file].name}</span></div>
                            <div className="progress active" id="progress"></div>
                            <div className="done" id="done">
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 1000 1000">
                                    <path id="path" d="M500,10C229.4,10,10,229.4,10,500c0,270.6,219.4,490,490,490c270.6,0,490-219.4,490-490C990,229.4,770.6,10,500,10z M500,967.7C241.7,967.7,32.3,758.3,32.3,500C32.3,241.7,241.7,32.3,500,32.3c258.3,0,467.7,209.4,467.7,467.7C967.7,758.3,758.3,967.7,500,967.7z M748.4,325L448,623.1L301.6,477.9c-4.4-4.3-11.4-4.3-15.8,0c-4.4,4.3-4.4,11.3,0,15.6l151.2,150c0.5,1.3,1.4,2.6,2.5,3.7c4.4,4.3,11.4,4.3,15.8,0l308.9-306.5c4.4-4.3,4.4-11.3,0-15.6C759.8,320.7,752.7,320.7,748.4,325z">
                                    </path>
                                </svg>
                            </div>
                    </div>
                </React.Fragment>
                
            );
        });



        document.getElementById("drop").classList.add("hidden");
        document.getElementById("footer").classList.add("hidden");
        document.getElementById("importar").classList.add("active");

        this.setState({ fileList: template, file: files[0] });

		Object.keys(files).forEach(file => {
			let load = 2000 + (file * 2000); // fake load
			setTimeout(() => {
				document.getElementById(`progress`).classList.remove("active");
				document.getElementById(`done`).classList.add("anim");
			}, load);
		});
    }


    render() {
        return (
            <React.Fragment>
                <div className="upload">
                    <div className="upload-files">
                    <header>
                    <p>
                        {/* <i className="fa fa-cloud-upload" aria-hidden="true"></i> */}
                        <span className="up">Invoicing Handler</span>
                        {/* <span className="load">load</span> */}
                    </p>
                    </header>
                        <form onSubmit={this.handleSubmit}>
                            {/* <input type="file" /> */}
                            <CopyOutlined style={{ fontSize: "120px", marginLeft: "120px", marginTop: "10px", padding: "10px", justifyContent: "center" }} />
                            <div className="body" id="drop" onDragOver={this.handleDrag} onDragEnter={this.handleDrag} onDrop={this.handleDrop}>
                                <i className="fa fa-file-text-o pointer-none" aria-hidden="true"></i>
                                <input id="test-input" ref={this.inputOpenFileRef} type="file" onChange={this.handleChange} />
                                <p className="pointer-none"><b>Drag and drop</b> files here <br /> or <span onClick={this.handleClick}>browse</span> to begin the upload</p>
                            </div>
                        </form>
                            <footer id="footer">
                                <div className="divider">
                                    <span>FILES</span>
                                </div>
                                <div className="list-files">
                                    {this.state.fileList}
                                </div>
                                {this.state.isLoading && (<div className="example">
                                    <Spin spinning={this.state.isLoading} />
                                </div>)}
                                <Button type="primary" className="importar" id="importar" onClick={this.handleSubmit}>SUBMIT</Button>
                            </footer>
                        </div>
                    </div>
            </React.Fragment>
        );
    }
};

export default Invoice;