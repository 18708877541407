import React, { Component } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Card, Button, message } from "antd";
import { ArrowRightOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { fetchPaymentInformation, createSession } from "../../service";

const stripePromise = loadStripe("pk_test_51HAgMhEfj1eVfYm3Vq21dViuvEoz2y3HpZnVgQxxHQ3jnup4BNDJRDsKjiAuMb1Pit49nQouNEpqakSn1p929Q2100AAsFKEq9");
const queryString = require('query-string');


class Checkout extends Component {

    constructor(props) {
        const parsed = queryString.parse(props.location.search);

        super(props);
        this.state = {
            loading: false,
            queryParams: parsed,
            error: false,
            price: "",
            invoice_id: "",
            stripeError: false,
            session_id: ""
        }
    }

    loadPaymentInformation = async () => {
        this.setState({ loading: true });
        const invoice_id = this.state.queryParams.invoice_id;
        const email = this.state.queryParams.email;

        if(!invoice_id || !email) {
            this.setState({ error: true, loading: false });
            return;
        }
        
        const response = await fetchPaymentInformation({ "email": email, "invoice_id": invoice_id });
        if(!response.data.data.length) {
            this.setState({ error: true, loading: false });
            message.info("We can't seem to find any information with those details.")
            return;
        }

        const price = response.data.formatted_price || response.data.data[0][2]['price'];
        this.setState({ price, invoice_id, loading: false });
        return;
    };

    fetchSession = async () => {
        const invoice_id = this.state.queryParams.invoice_id;
        const email = this.state.queryParams.email;

        if(!invoice_id || !email) {
            return;
        }

        if(this.state.error) {
            return;
        }

        const response = await createSession({ "email": email, "invoice_id": invoice_id });
        if(!response.data.error) {
            this.setState({ session_id: response.data.id });
        } else {
            this.setState({ error: true, stripeError: true });
        }
    }

    async componentDidMount() {
        await this.loadPaymentInformation();
        await this.fetchSession();
    }

    handleCheckout = async (e) => {
        const session_id = this.state.session_id;
        const stripe = await stripePromise; 

        const { error } = await stripe.redirectToCheckout({
            sessionId: session_id,
        });

        message.info(error.message);
        this.setState({ stripeError: true, error: true });
    }

    render() {
        if(this.state.error) {
            let checkoutFailed = (
                <React.Fragment>
                    <span>Checkout Information <CloseOutlined style={{background: "red"}}/> </span>
                </React.Fragment>
            );
            if(this.state.loading) {
                checkoutFailed = "";
            }
            let errorMessage = (
                <span>We can't seem to find any information in our system with those details. Please reach out to <b>support@edisonindustrials.com</b> with your invoice id for further questions.</span>
            );
            if(this.state.stripeError) {
                errorMessage = (
                    <span>There's seems to be a problem with our payment handler currently. We are working on getting this resolved as soon as possible. Please check back after some time to complete your checkout. Please reach out to <b>support@edisonindustrials.com</b> with your invoice id for further questions.</span>
                );
            } 

            return (
                <React.Fragment>
                <div style={{ background: "#f1f1f1"}}>
                    <div style={{ width: "500px", marginTop: "-100px"}}>
                        <Card loading={this.state.loading} style={{ marginTop: "-20px", width: "200" }} size="default" title={checkoutFailed}>
                        {errorMessage}
                        </Card>
                        <div style={{ marginTop: "20px", paddingTop: "20px" }}>
                            <Button onClick={this.handleCheckout} disabled={true} type="primary" style={{ width: "500px", background: "#3c4257", borderColor: "#3c4257" }}>
                                <span style={{ color: "#ffffff"}}>PROCEED TO SECURE CHECKOUT <ArrowRightOutlined /></span>
                            </Button>
                        </div>
                    </div>
                </div>
                
            </React.Fragment>
            );
        } else {
            let checkoutRight = (
                <React.Fragment>
                    <span>Checkout Information <CheckOutlined style={{ background: 'green'}} /> </span>
                </React.Fragment>
            );
            if(this.state.loading) {
                checkoutRight = "";
            }
            return (
                <React.Fragment>
                <div style={{ background: "#f1f1f1"}}>
                    <div style={{ width: "500px", marginTop: "-100px"}}>
                        <Card loading={this.state.loading} style={{ marginTop: "-20px", width: "200" }} size="default" title={checkoutRight}>
                            <span>We are excited to have you as a customer! Your invoice id is <b>{this.state.invoice_id}</b> and your total down payment is <b>${this.state.price}</b>. If this information looks correct, proceed to the checkout page. </span>
                            <hr></hr>
                            Please click the checkout button below to proceed to the payment page. You will be redirected to a checkout page to complete your down payment.
                        </Card>
                        <div style={{ marginTop: "20px", paddingTop: "20px" }}>
                            <Button onClick={this.handleCheckout} disabled={false} type="primary" style={{ width: "500px", background: "#3c4257", borderColor: "#3c4257" }}>
                                <span style={{ color: "#ffffff"}}>PROCEED TO SECURE CHECKOUT <ArrowRightOutlined /></span>
                            </Button>
                        </div>
                    </div>
                </div>
                
            </React.Fragment>
            )
        }
        

        
    }
};

export default Checkout;

