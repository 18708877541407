import axios from "axios";
import { message } from "antd";

const server_url = "https://api.edisonindustrials.com/api/v1"

export async function sendEmail(data) {
    try {
        const response = axios.post(`${server_url}/email`, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response;
    } catch (e) {
        message.error('Internal server error' + e.message);
        throw e;
    }
}

export async function uploadInvoice(formData) {
    try {   
        const response = axios.post(`${server_url}/invoice`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response;
    } catch(e) {
        message.error('Internal server error' + e.message);
        throw e;
    }
}

export async function fetchPaymentInformation(data) {
    try {
        const response = axios.post(`${server_url}/payment`, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response;
    } catch (e) {
        message.error('Internal server error' + e.message);
        throw e;
    }
}

export async function createSession(data) {
    try {
        const response = axios.post(`${server_url}/checkout`, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response;
    } catch (e) {
        message.error('Internal server error' + e.message);
        throw e;
    }
}
