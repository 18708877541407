import React from 'react';
import Routes from './routes.js';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import './App.css';

function App(props) {
  return (
    <React.Fragment>
      <Routes {...props} />
    </React.Fragment>
  );
}

export default App;
